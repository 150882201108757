<template>
  <div class="ui grid container-1" id="habout">
    <div class="content">
      <h3>CREATING FUTURES</h3>
      <h1>TOGETHER</h1>
      <p>Whatever your goal, TayoCash is with you all the way.</p>

      <router-link class="yellow" to="/about" v-scroll-to="'#mssn'">
        LEARN MORE
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'content1'
}
</script>

<style scoped lang='scss'></style>
